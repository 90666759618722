<template>
    <div class="lds-ellipsis" v-if="!is_data_ready">
        <div></div>
        <div></div>
        <div></div
        >
        <div></div>
    </div>

    <div class="multiple-tables-wrap" v-else>
        <div class="table-responsive">
            <template v-for="(group, index) in standings">
                <div class="standings_wrapper" :key="index">
                    <template v-if="group.length > 0 && group[0].group_id">
                        <h2>{{group[0].group}}</h2>
                    </template>
                    <table class="table standings-table-cricket">
                        <thead>
                        <th style="text-align: left;"><span> {{ $t("Teams") }}</span></th>
                        <th> M</th>
                        <th> W</th>
                        <th> L</th>
                        <th> D</th>
                        <th> PTS</th>
                        <th> NET RR</th>
                        </thead>
                        <tbody v-if="group.length > 0">
                        <tr v-for="(standing, index2) in group" :key="index2" :class="(matchTeams.includes(standing.team_id)) ? 'heighlight' : ''">
                            <td style="text-align: left;"> {{standing.team.name}}</td>
                            <td> {{standing.matches}}</td>
                            <td> {{standing.won}}</td>
                            <td> {{standing.lost}}</td>
                            <td> {{standing.tied}}</td>
                            <td> {{standing.points}}</td>
                            <td>{{standing.net_RR}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </div>
    </div>

</template>

<script>
    export default {
        name: "standing",

        data() {
            return {
                standings: null,
                matchTeams: null,
            }
        },
        mounted() {
            this.getStanding()
        },
        serverPrefetch() {
            return this.getStanding();
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_standing_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_standing_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_standing_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        methods: {
            getStanding() {
                this.is_data_ready = false;

                return this.$axios
                    .get(this.$MATCH_STANDING_API, {
                        params: {
                            match: this.$route.params.slug,
                        }
                    })
                    .then(response => {
                        if (response.data) {
                            this.standings = response.data.result.standings;
                            this.matchTeams = response.data.result.teams;
                            this.is_data_ready = true;
                        }
                    })
            },
        },

    }
</script>
<style>
    .standings_wrapper h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 22px;
        color: #0c2251;
        margin-bottom: 11px;
        margin-top: 20px;
    }
</style>
